import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { IHSRCone } from '../../../common/model/graphql-types';
import { HSRLightConeIcon } from './hsr-light-cone-icon';
import { HSRLightConeCard } from './hsr-light-cone-card';
import { HSRLightConeProfile } from './hsr-light-cone-profile';
import { HSRLightConeMin } from './hsr-light-cone-min';

interface IProps {
  id?: string;
  slug?: string;
  name?: string | number;
  enablePopover?: boolean;
  showLabel?: boolean;
  showTags?: boolean;
  mode: string;
  unitId?: string;
  variant?: string;
  number?: number;
  superImp?: string;
  hidename?: boolean;
}

export const HSRLightCone: React.FC<IProps> = ({
  id,
  unitId,
  mode,
  showLabel,
  slug,
  enablePopover,
  number,
  superImp,
  name,
  hidename
}) => {
  const allHSRCones = useStaticQuery(graphql`
    query {
      allContentfulHsrLightCone(sort: { fields: slug, order: ASC }) {
        nodes {
          ...HsrLightConeFieldsFragment
        }
      }
    }
  `);
  if ((!id && !slug && !unitId && !name) || !mode) {
    return <div>Err</div>;
  }

  const character = allHSRCones.allContentfulHsrLightCone.nodes.find(
    (emp: IHSRCone) =>
      id ? emp.id === id : name ? emp.name === name : emp.slug === slug
  );

  if (!character) {
    return <div>Err</div>;
  }

  return (
    <>
      {mode === 'icon' && (
        <HSRLightConeIcon
          employee={character}
          showLabel={showLabel}
          enablePopover={enablePopover}
        />
      )}
      {mode === 'card' && <HSRLightConeCard cone={character} />}
      {mode === 'profile' && (
        <HSRLightConeProfile
          cone={character}
          number={number}
          superImp={superImp}
        />
      )}
      {mode === 'min' && (
        <HSRLightConeMin
          cone={character}
          superImp={superImp}
          hidename={hidename}
        />
      )}
    </>
  );
};
